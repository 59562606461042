import { TYPES } from "./examplesTable"
import moment from "moment"

const config = {
  url: '/example/:id',
  getTitle: id => id === 'create' ? 'Создание примера' : 'Редактирование примера',
  getApiUrl: id => id === 'create' ? '/examples' : `/examples/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'type_input',
            title: 'Тип Input',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'type_textarea',
            title: 'Тип TextArea',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 255,
            required: false,
          },
          {
            key: 'type_ckeditor',
            title: 'Тип CKEditor',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
          {
            key: 'type_static_select',
            title: 'Тип StaticSelect',
            component: 'StaticSelect',
            options: TYPES,
            placeholder: 'Выберите тип',
            required: false,
            defaultValue: "0",
          },
          {
            key: 'type_dynamic_select_page_id',
            title: 'Тип DynamicSelect',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=pages&sort[field]=title&sort[type]=asc&limit=20',
            filter: 'pages',
            keyName: 'pages_title',
            placeholder: 'Выберите страницу',
            search: true,
            mode: 'single',
            getOptionValue: data => data.title,
            required: false,
          },
          {
            key: 'tag_ids',
            title: 'Теги',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=tags&sort[field]=name&sort[type]=asc&limit=20',
            filter: 'tags',
            placeholder: 'Выберите теги',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.name,
            required: false,
          },
        ],
        right: [
          {
            key: 'type_date_unix',
            title: 'Тип Дата unix',
            component: 'Date',
            placeholder: 'Введите',
            unix: true,
            showTime: true,
            style: { width: '100%' },
            defaultValue: moment().unix(),
            getValue: data => data.type_date_unix,
            required: false,
          },
          {
            key: 'type_date_timestamp',
            title: 'Тип Дата timestamp',
            component: 'Date',
            placeholder: 'Введите',
            showTime: true,
            format: 'YYYY-MM-DD HH:mm:ss',
            style: { width: '100%' },
            defaultValue: moment().format('YYYY-MM-DD HH:mm:ss'),
            getValue: data => moment(data.type_date_timestamp, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            required: false,
          },
          {
            key: 'type_date_date',
            title: 'Тип Дата date',
            component: 'Date',
            placeholder: 'Введите',
            showTime: false,
            format: 'YYYY-MM-DD',
            style: { width: '100%' },
            defaultValue: moment().format('YYYY-MM-DD'),
            getValue: data => moment(data.type_date_timestamp, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            required: false,
          },
          {
            key: 'type_switch',
            title: 'Тип Switch',
            component: 'Switch',
            defaultValue: 0,
          },
          {
            key: 'type_pdf_storage_id',
            title: 'Тип Single file (pdf)',
            component: 'File',
            multiple: false,
            maxCount: 1,
            keyName: 'type_pdf_storage_id',
            keyList: 'type_pdf_storage',
            allowTypes: ['pdf'],
          },
          {
            key: 'file_ids',
            title: 'Тип Many files (xlsx)',
            component: 'File',
            multiple: true,
            maxCount: 4,
            keyName: 'file_ids',
            keyList: 'files',
            allowTypes: ['xlsx'],
          },
          {
            key: 'type_image_storage_id',
            title: 'Тип Single image (jpg)',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'type_image_storage_id',
            keyList: 'type_image_storage',
            allowTypes: ['jpg'],
            showInfo: false,
            isDigitalPetr: false,
          },
          {
            key: 'image_ids',
            title: 'Many images (jpg)',
            component: 'Image',
            multiple: true,
            maxCount: 4,
            keyName: 'image_ids',
            keyList: 'images',
            allowTypes: ['jpg'],
            showInfo: true,
            isDigitalPetr: true,
          },
        ],
        both: [],
      },
    },
  ],
}

export default config
