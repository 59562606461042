const config = {
  url: '/place/:id',
  getTitle: id => id === 'create' ? 'Создание места хранения' : 'Редактирование места хранения',
  getApiUrl: id => id === 'create' ? '/places' : `/places/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'cipher',
            title: 'Шифр',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
        ],
        right: [
          {
            key: 'description',
            title: 'Описание',
            component: 'TextArea',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
        ],
        both: [],
      }
    }
  ]
}

export default config