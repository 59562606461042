import moment from "moment"

const config = {
  url: '/events',
  title: 'События',
  endpoint: '/admin-search?filter=events',
  type: 'table',
  getUrl: id => `/event/${id}`,
  getApiUrl: id => `/events/${id}`,
  search: false,
  key: 'events',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'date',
      title: 'Дата',
      isViewByDefault: true,
      getValue: data => moment(data.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      width: 150,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: false,
        format: 'YYYY-MM-DD',
        required: false,
      },
    },
  ],
  filters: [],
}

export default config