const config = {
  url: '/places',
  title: 'Места хранения',
  endpoint: '/admin-search?filter=places',
  type: 'table',
  getUrl: id => `/place/${id}`,
  getApiUrl: id => `/places/${id}`,
  search: false,
  key: 'places',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'cipher',
      width: 150,
      title: 'Шифр',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.cipher,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: false,
      },
    },
    {
      key: 'description',
      title: 'Описание',
      isViewByDefault: true,
      getValue: data => data.description,
      edit: {
        component: 'TextArea',
        placeholder: 'Введите',
        maxLength: 255,
        required: false,
      },
    },
  ],
  filters: [],
}

export default config