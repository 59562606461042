import React, { PureComponent } from 'react'
import { SettingOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { Modal, Select, Tooltip } from 'antd'

import { updateSettings } from '../redux/actions/UserActions'

const { Option } = Select

class TableSettings extends PureComponent {
  state = {
    isOpenModal: false,
    value: [],
    isValueChanged: false,
  }

  componentDidMount() {
    const { config, user } = this.props

    if (!user.settings.tables[config.key]) {
      this.props.updateSettings(
        'tables',
        config.key,
        config.table.filter(item => item.isViewByDefault).map(item => item.key)
      )
    }
  }

  render() {
    const { config, user } = this.props

    if (!user.settings.tables[config.key]) {
      return null
    }

    const options = config.table.map(item => (
      <Option key={item.key} value={item.key}>
        {item.title}
      </Option>
    ))

    return (
      <>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ isOpenModal: true })
          }}
        >
          <Tooltip placement="bottom" mouseEnterDelay={0.75} title="Настройка отображаемых столбцов">
            <SettingOutlined />
          </Tooltip>
        </a>

        <Modal
          title="Настройка отображения"
          visible={this.state.isOpenModal}
          onOk={() => {
            this.setState({ isOpenModal: false })
            if (this.state.isValueChanged) {
              this.props.updateSettings(
                'tables',
                config.key,
                this.state.value
              )
            }
          }}
          onCancel={() => this.setState({ isOpenModal: false, value: [], isValueChanged: false })}
          okText="Сохранить"
          cancelText="Отмена"
        >
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            placeholder="Выберите столбцы для отображения"
            value={this.state.value.length === 0 ? user.settings.tables[config.key] : this.state.value}
            onChange={(value) => this.setState({ value, isValueChanged: true })}
          >
            {options}
          </Select>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateSettings: (section, field, value) => dispatch(updateSettings(section, field, value)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(TableSettings)
