import axios from 'axios'

const url = process.env.REACT_APP_API_HOST

function logIn(login, password) {
  return axios.post(url + '/auth/login', {
    login,
    password,
  }, {
    headers: {
      Accept: 'application/json'
    }
  })
}

function logOut(token) {
  return axios.get(
    url + '/auth/logout',
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
}

const UserAPI = {
  logIn,
  logOut
}

export default UserAPI
