import produce from 'immer'
import {
  INIT_CARD,
  SET_FIELD,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CLEAR_REDIRECT,
  GET_REQUEST,
  GET_SUCCESS,
  SET_VALIDATION,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
} from '../actions/CardActions'

const initialState = {
  isLoading: false,
  fields: {},
  url: '',
  redirect: '',
  errorFields: [],
}

export default function state(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoading = false
      })
    case UPDATE_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case SET_VALIDATION:
      return produce(state, draftState => {
        draftState.errorFields = action.payload.errorFields
      })
    case GET_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
        draftState.fields = initialState.fields
        draftState.url = action.payload.cardUrl
      })
    case GET_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.fields = action.payload.data
      })
    case CLEAR_REDIRECT:
      return produce(state, draftState => {
        draftState.redirect = ''
      })
    case CREATE_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case CREATE_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.redirect = draftState.url.replace(':id', action.payload.data.id)
        draftState.fields = action.payload.data
      })
    case SET_FIELD:
      return produce(state, draftState => {
        draftState.fields[action.payload.field] = action.payload.value
      })
    case INIT_CARD:
      return produce(state, draftState => {
        draftState.fields = action.payload.fields
        draftState.url = action.payload.url
      })
    default:
      return state
  }
}