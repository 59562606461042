
import pagesTable from './pagesTable'
import pagesCard from './pagesCard'
import usersTable from './usersTable'
import usersCard from './usersCard'
import examplesTable from './examplesTable'
import examplesCard from './examplesCard'
import tagsTable from './tagsTable'
import tagsCard from './tagsCard'
import documentTypesTable from './documentTypesTable'
import documentTypesCard from './documentTypesCard'
import placesTable from './placesTable'
import placesCard from './placesCard'
import documentsTable from './documentsTable'
import documentsCard from './documentsCard'
import eventsTable from './eventsTable'
import eventsCard from './eventsCard'
import articlesTable from './articlesTable'
import articlesCard from './articlesCard'

const pages = [
  pagesTable,
  pagesCard,
  usersTable,
  usersCard,
  examplesTable,
  examplesCard,
  tagsTable,
  tagsCard,
  documentTypesTable,
  documentTypesCard,
  placesTable,
  placesCard,
  documentsTable,
  documentsCard,
  eventsTable,
  eventsCard,
  articlesTable,
  articlesCard,
]

const menu = [
  {
    title: 'Документы',
    page: documentsTable,
  },
  {
    title: 'События',
    page: eventsTable,
  },
  {
    title: 'Статьи',
    page: articlesTable,
  },
  {
    title: 'Страницы',
    page: pagesTable,
  },
  {
    title: 'Типы документов',
    page: documentTypesTable,
  },
  {
    title: 'Места хранения',
    page: placesTable,
  },
  {
    title: 'Пользователи',
    page: usersTable,
  },
]

const config = {
  pages,
  menu
}

export default config