const config = {
  url: '/document-type/:id',
  getTitle: id => id === 'create' ? 'Создание типа документа' : 'Редактирование типа документа',
  getApiUrl: id => id === 'create' ? '/document-types' : `/document-types/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
        ],
        right: [],
        both: [],
      }
    }
  ]
}

export default config