import React, { PureComponent } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'

class DateField extends PureComponent {
  render() {
    let defaultValue = null

    if (this.props.value) {
      if (this.props.unix) {
        defaultValue = moment.unix(this.props.value)
      } else {
        defaultValue = moment(this.props.value, this.props.format)
      }
    }

    const viewDateFormat = this.props.showTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'

    let showTime = false

    if (this.props.showTime) {
      showTime = {
        defaultValue: this.props.value ? moment(this.props.value, 'HH:mm') : null
      }
    }

    return (
      <DatePicker
        value={defaultValue}
        format={viewDateFormat}
        showTime={showTime}
        style={this.props.style}
        status={this.props.status}
        allowClear={typeof this.props.allowClear !== 'undefined' ? this.props.allowClear : true}
        onChange={(date, dateString) => {
          let value = moment(dateString, viewDateFormat)

          if (dateString) {
            if (this.props.unix) {
              value = value.unix()
            } else {
              value = value.format(this.props.format)
            }
          } else {
            value = null
          }

          if (this.props.onChange) {
            this.props.onChange(
              this.props.name,
              value
            )
          }

          if (this.props.onSave) {
            this.props.onSave(
              this.props.name,
              value
            )
          }
        }}

      />
    )
  }
}

export default DateField