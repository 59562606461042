import produce from 'immer'
import {
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../actions/PersonalActions'

const initialState = {
  userData: {
    isLoading: false,
    data: {
      name: '',
      surname: '',
      email: '',
      role: '',
    },
  }
}

export default function state(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_REQUEST:
      return produce(state, draftState => {
        draftState.userData.isLoading = true
      })
    case UPDATE_USER_SUCCESS:
      return produce(state, draftState => {
        draftState.userData.isLoading = false
        draftState.userData.data = {
          ...draftState.userData.data,
          ...action.payload.data,
        }
      })
    case USER_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.userData.isLoading = true
      })
    case USER_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.userData.isLoading = false
        draftState.userData.data = {
          ...draftState.userData.data,
          ...action.payload.data,
        }
      })
    default:
      return state
  }
}