const config = {
  url: '/page/:id',
  getTitle: id => id === 'create' ? 'Создание страницы' : 'Редактирование страницы',
  getApiUrl: id => id === 'create' ? '/pages' : `/pages/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'meta_keywords',
            title: 'Meta ключевые слова',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'meta_description',
            title: 'Meta описание',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 255,
            required: false,
          },
        ],
        right: [
          {
            key: 'url',
            title: 'URL',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'hidden',
            title: 'Скрыть',
            component: 'Switch',
            defaultValue: 0,
          },
          {
            key: 'image_storage_id',
            title: 'Изображение (jpg)',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
          },
        ],
        both: [],
      },
    },
    {
      key: 'content',
      title: 'Содержимое',
      children: {
        left: [],
        right: [],
        both: [
          {
            key: 'content',
            title: 'Содержимое',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
          {
            key: 'content_footer',
            title: 'Подвал',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      },
    },
  ],
}

export default config