import produce from 'immer'
import {
  TABLE_LOAD_REQUEST,
  TABLE_LOAD_SUCCESS,
  SET_REQ_PARAM,
  RESET_REQ_PARAM,
  SET_TABLE_PARAM,
  UPDATE_CELL_REQUEST,
  UPDATE_CELL_SUCCESS,
  DELETE_RECORD_REQUEST,
  DELETE_RECORD_SUCCESS
} from '../actions/TableActions'

const initialState = {
  data: {
    isLoading: false,
    items: [],
    page: 1,
    isTotalReached: false,
    savingItems: [],
  },
  reqParams: {
    sort: {
      field: 'id',
      type: 'desc',
    },
    key: '',
    params: {},
  },
}

export default function state(state = initialState, action) {
  switch (action.type) {
    case DELETE_RECORD_REQUEST:
      return produce(state, draftState => {
        draftState.data.savingItems.push(action.payload.id)
      });
    case DELETE_RECORD_SUCCESS:
      return produce(state, draftState => {
        draftState.data.savingItems = draftState.data.savingItems.filter(
          id => id !== action.payload.data.id
        )
        draftState.data.items = draftState.data.items.filter(item =>
          item.id !== action.payload.data.id
        )
      });
    case UPDATE_CELL_REQUEST:
      return produce(state, draftState => {
        draftState.data.savingItems.push(action.payload.id)
      });
    case UPDATE_CELL_SUCCESS:
      return produce(state, draftState => {
        draftState.data.savingItems = draftState.data.savingItems.filter(
          id => id !== action.payload.data.id
        )
      });
    case TABLE_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.data.isLoading = true
        draftState.data.isTotalReached = false

        draftState.data.page = action.payload.request.params['page']
        if (draftState.data.page === 1) {
          draftState.data.items = []
        }

        Object.keys(initialState.reqParams).forEach(key => {
          if (Object.keys(action.payload.request.params).indexOf(key)) {
            draftState.reqParams[key] = action.payload.request.params[key]
          } else {
            draftState.reqParams[key] = initialState.reqParams[key]
          }
        })

      })
    case TABLE_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.data.isLoading = false

        if (draftState.page === 1) {
          draftState.data.items = action.payload.data.results
        } else {
          draftState.data.isTotalReached = action.payload.data.results.length === 0
          draftState.data.items = draftState.data.items.concat(action.payload.data.results)
        }
      })
    case SET_REQ_PARAM:
      return produce(state, draftState => {
        draftState.reqParams[action.payload.param] = action.payload.value
      })
    case RESET_REQ_PARAM:
      return produce(state, draftState => {
        draftState.reqParams = initialState.reqParams
      })
    case SET_TABLE_PARAM:
      return produce(state, draftState => {
        let index = draftState.data.items.findIndex(
          item => item.id === action.payload.id
        )
        draftState.data.items[index][action.payload.param] = action.payload.value
        Object.keys(action.payload.other).forEach(key => {
          draftState.data.items[index][key] = action.payload.other[key];
        })
      })
    default:
      return state
  }
}