import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Input } from 'antd'

import { getTable, setReqParam } from '../redux/actions/TableActions'

let searchTimer

class Search extends PureComponent {
  startSearch(e) {
    const value = e.target.value
    this.props.setReqParam('key', value)
    clearTimeout(searchTimer)
    searchTimer = setTimeout(() => {
      const params = {
        ...this.props.table.reqParams,
        key: value,
      }
      this.props.getTable(
        this.props.config.url,
        this.props.config.endpoint,
        1,
        params
      );
    }, 500)
  }

  render() {
    const { Search } = Input
    return (
      <Search
        placeholder="Поиск..."
        onChange={this.startSearch.bind(this)}
        enterButton
        size="large"
        className="Search_input"
        style={{ marginBottom: '.5rem' }}
        value={this.props.table.reqParams.key}
      />
    )
  }
}

const mapStateToProps = store => {
  return {
    table: store.table,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTable: (url, endpoint, page = 1, params = {}) =>
      dispatch(getTable(url, endpoint, page, params)),
    setReqParam: (param, value) => dispatch(setReqParam(param, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)