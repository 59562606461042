import React, { PureComponent } from 'react'
import { Form, Input, Button, Row, Col, Spin } from 'antd'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'

import { getUserData, updateUserData } from '../redux/actions/PersonalActions'

class Personal extends PureComponent {
  componentDidMount() {
    this.props.getUserData()
  }

  onFinish(values) {
    if (!values.password) {
      delete values.password
      delete values.password_repeat
    }
    this.props.updateUserData(values)
  }

  render() {
    const { personal } = this.props

    const antIcon = <LoadingOutlined style={{ fontSize: 40 }} />

    return (
      <>
        <Helmet>
          <title>Peterscript | Личный кабинет</title>
        </Helmet>

        <h1>Личный кабинет</h1>

        <Row>
          <Col span={12}>
            {personal.userData.isLoading && !personal.userData.data.name && (
              <div className="Table__loader">
                <Spin indicator={antIcon} />
              </div>
            )}

            {personal.userData.data.name && (
              <Spin spinning={personal.userData.isLoading} indicator={antIcon}>
                <Form
                  name="basic"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                  initialValues={personal.userData.data}
                  onFinish={this.onFinish.bind(this)}
                  autoComplete="off"
                  labelAlign="left"
                >

                  <Form.Item
                    label="Имя"
                    name="name"
                    rules={[{ required: true, message: 'Заполните это поле' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Фамилия"
                    name="surname"
                    rules={[{ required: true, message: 'Заполните это поле' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[{ required: true, message: 'Заполните это поле' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Роль"
                  >
                    {personal.userData.data.role}
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Пароль"
                    rules={[
                      {
                        required: false,
                        message: 'Введите пароль',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="password_repeat"
                    label="Повторите пароль"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: false,
                        message: 'Введите повтор пароля',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value && !getFieldValue('password') ||
                            getFieldValue('password') === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Введенные пароли не совпадают'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                    <Button type="primary" htmlType="submit">
                      Сохранить
                    </Button>
                  </Form.Item>

                </Form>
              </Spin>
            )}
          </Col>
        </Row>

      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    personal: store.personal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUserData: () => dispatch(getUserData()),
    updateUserData: (data) => dispatch(updateUserData(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Personal)
