import React, { PureComponent } from 'react'
import { Row, Col } from 'antd'
import { connect } from 'react-redux'

import getField from '../utils/getField'
import { setField } from '../redux/actions/CardActions'

class TabLayout extends PureComponent {
  constructor() {
    super()
    this.getFieldsForCol = this.getFieldsForCol.bind(this)
  }

  getFieldsForCol(col) {
    const { tab, card } = this.props
    let fields = []

    if (tab.children[col]) {
      fields = tab.children[col].map(item => {
        return (
          <div key={item.key} className={'Tab__label'}>
            <div className={'Tab__label-title ' + (item.required ? 'Tab__label-title-required' : '')}>
              {item.title}
            </div>
            {getField({
              ...item,
              key: item.key,
              value: card.fields[item.key] ? card.fields[item.key] : item.defaultValue,
              onChange: (key, value) => this.props.setField(key, value),
              status: card.errorFields.indexOf(item.key) !== -1 ? 'error' : undefined
            })}
          </div>
        )
      })
    }

    return fields
  }

  render() {
    const left = this.getFieldsForCol('left')
    const right = this.getFieldsForCol('right')
    const both = this.getFieldsForCol('both')

    return (
      <Row gutter={[20, 20]}>
        {left.length > 0 && (
          <Col span={12}>
            {left}
          </Col>
        )}
        {right.length > 0 && (
          <Col span={12}>
            {right}
          </Col>
        )}
        {both.length > 0 && (
          <Col span={24}>
            {both}
          </Col>
        )}
      </Row>
    )
  }
}

const mapStateToProps = store => {
  return {
    card: store.card,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(setField(field, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TabLayout)