import React, { PureComponent } from 'react'
import { Select } from 'antd'

const { Option } = Select

class StaticSelect extends PureComponent {
  render() {
    let items = []

    Object.keys(this.props.options).forEach(key => {
      items.push(
        <Option key={key} value={String(key)}>
          {this.props.options[key]}
        </Option>
      )
    })

    let value = this.props.value

    if (!Array.isArray(value)) {
      value = value ? String(value) : undefined;
    } else {
      value = value.map(item => String(item))
    }

    return (
      <Select
        style={{ width: '100%' }}
        placeholder={this.props.placeholder}
        onChange={(value) => {
          if (this.props.onChange) {
            this.props.onChange(this.props.name, value)
          }

          if (this.props.onSave) {
            this.props.onSave(this.props.name, value)
          }
        }}
        mode={this.props.mode}
        value={value}
        status={this.props.status}
        defaultValue={this.props.defaultValue}
      >
        {items}
      </Select>
    )
  }
}

export default StaticSelect