const config = {
  url: '/document-types',
  title: 'Типы документов',
  endpoint: '/admin-search?filter=document_types',
  type: 'table',
  getUrl: id => `/document-type/${id}`,
  getApiUrl: id => `/document-types/${id}`,
  search: false,
  key: 'document-types',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
  ],
  filters: [],
}

export default config