import StaticSelect from "./StaticSelect"
import DynamicSelect from "./DynamicSelect"
import Input from './Input'
import TextArea from "./TextArea"
import Date from './Date'
import Switch from './Switch'
import Password from "./Password"
import File from "./File"
import Image from "./Image"
import CKEditor from "./CKEditor"

const fields = {
  StaticSelect,
  DynamicSelect,
  Input,
  TextArea,
  Date,
  Switch,
  Password,
  File,
  Image,
  CKEditor,
}

export default fields