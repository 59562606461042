import React, { PureComponent } from 'react'
import { FileExcelOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { Modal, Select, Space, Tooltip } from 'antd'
import qs from 'qs'
import axios from 'axios'
import fileDownload from 'js-file-download'

import { updateUnloading } from '../redux/actions/UserActions'

const { Option } = Select

class TableUnloading extends PureComponent {
  state = {
    isOpenModal: false,
  }

  render() {
    const { user, table, config } = this.props
    const url = process.env.REACT_APP_API_HOST
    const userAccessToken = user.attributes.token

    return (
      <>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ isOpenModal: true })
          }}
        >
          <Tooltip placement="bottom" mouseEnterDelay={0.75} title="Выгрузка данных">
            <FileExcelOutlined />
          </Tooltip>
        </a>

        <Modal
          title="Настройка выгрузки"
          visible={this.state.isOpenModal}
          onOk={() => {
            this.setState({ isOpenModal: false })

            let params = Object.assign({}, table.reqParams);
            params.params = Object.assign({}, table.reqParams.params)

            if (user.unloading.records === 1) {
              params.params.id = table.data.items.map(item => item.id)
            }

            params.unloading = {
              fields: {},
              values: {},
            }
            config.table.forEach(item => {
              if (user.unloading.fields === 2 || user.settings.tables[config.key].indexOf(item.key) !== -1) {
                params.unloading.fields[item.key] = item.title

                if (item.edit) {
                  switch (item.edit.component) {
                    case 'DynamicSelect':
                      params.unloading.fields[item.edit.keyName] = item.title
                      delete params.unloading.fields[item.key]
                      break
                    case 'StaticSelect':
                      params.unloading.values[item.key] = item.edit.options
                      break
                    case 'Switch':
                      params.unloading.values[item.key] = {
                        0: 'Нет',
                        1: 'Да',
                      }
                      break
                    default:
                      break
                  }
                }
              }
            })

            params.filter = config.key
            const urlParams = qs.stringify(params)

            axios({
              url: `${url}/unloading?${urlParams}`,
              method: 'get',
              headers: {
                'Authorization': `Bearer ${userAccessToken}`,
              },
              responseType: 'arraybuffer',
              withCredentials: false
            }).then(response => {
              fileDownload(response.data, `${config.key}.xlsx`)
            })
          }}
          onCancel={() => this.setState({ isOpenModal: false })}
          okText="Выгрузить"
          cancelText="Отмена"
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Select
              style={{ width: '100%' }}
              value={user.unloading.records}
              onChange={(value) => this.props.updateUnloading('records', value)}
            >
              <Option value={1}>Все записи на текущей странице</Option>
              <Option value={2}>Все записи</Option>
            </Select>

            <Select
              style={{ width: '100%' }}
              value={user.unloading.fields}
              onChange={(value) => this.props.updateUnloading('fields', value)}
            >
              <Option value={1}>Текущий набор полей</Option>
              <Option value={2}>Все поля</Option>
            </Select>
          </Space>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    table: store.table,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateUnloading: (field, value) => dispatch(updateUnloading(field, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableUnloading)