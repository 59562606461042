import moment from "moment"

const config = {
  url: '/event/:id',
  getTitle: id => id === 'create' ? 'Создание события' : 'Редактирование события',
  getApiUrl: id => id === 'create' ? '/events' : `/events/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'date',
            title: 'Дата',
            component: 'Date',
            placeholder: 'Введите',
            showTime: false,
            format: 'YYYY-MM-DD',
            style: { width: '100%' },
            defaultValue: moment().format('YYYY-MM-DD'),
            getValue: data => moment(data.type_date_timestamp, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            required: true,
            allowClear: false,
          },
          {
            key: 'anons',
            title: 'Анонс',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 1000,
            required: false,
          },
        ],
        right: [
          {
            key: 'image_storage_id',
            title: 'Изображение',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
            showInfo: false,
            isDigitalPetr: false,
          },
        ],
        both: [
          {
            key: 'content',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    }
  ]
}

export default config