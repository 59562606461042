import UserAPI from '../../api/UserAPI'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_NOT_FOUND = 'USER_LOGIN_NOT_FOUND'
export const USER_LOGIN_TOO_MANY_REQUESTS = 'USER_LOGIN_TOO_MANY_REQUESTS'
export const USER_LOGIN_SERVER_ERROR = 'USER_LOGIN_SERVER_ERROR'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_INIT_LOGIN = 'USER_INIT_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_UPDATE_SETTINGS = 'USER_UPDATE_SETTINGS'
export const USER_UPDATE_UNLOADING = 'USER_UPDATE_UNLOADING'

export function logInUser(login, password) {
  return dispatch => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    UserAPI.logIn(login, password)
      .then(res => {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: res.data,
        })
      })
      .catch(e => {
        switch (e.response.status) {
          case 404:
            dispatch({
              type: USER_LOGIN_NOT_FOUND,
            })
            break
          case 429:
            dispatch({
              type: USER_LOGIN_TOO_MANY_REQUESTS,
            })
            break
          default:
            dispatch({
              type: USER_LOGIN_SERVER_ERROR,
            })
            break
        }
      })
  }
}

export function logOutUser(token) {
  return dispatch => {
    UserAPI.logOut(token)
      .then(res => {
        dispatch({
          type: USER_LOGOUT,
        })
      })
      .catch(e => {
        dispatch({
          type: USER_LOGOUT,
        })
      })
  }
}

export function initLoginPage() {
  return dispatch => {
    dispatch({
      type: USER_INIT_LOGIN,
    })
  }
}

export function updateSettings(section, field, value) {
  return dispatch => {
    dispatch({
      type: USER_UPDATE_SETTINGS,
      payload: {
        section,
        field,
        value
      }
    })
  }
}

export function updateUnloading(field, value) {
  return dispatch => {
    dispatch({
      type: USER_UPDATE_UNLOADING,
      payload: {
        field,
        value
      }
    })
  }
}