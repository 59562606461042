import React, { PureComponent } from 'react'
import { Switch } from 'antd'

class SwitchField extends PureComponent {
  render() {
    return (
      <>
        {this.props.placeholder ? this.props.placeholder + ' ' : ''}
        <Switch
          checked={this.props.value === 1 ? true : false}
          onChange={(value) => {
            if (this.props.onChange) {
              this.props.onChange(this.props.name, value ? 1 : 0)
            }
            if (this.props.onSave) {
              this.props.onSave(this.props.name, value ? 1 : 0)
            }
          }}
        />
      </>
    )
  }
}

export default SwitchField