import React, { PureComponent } from 'react'
import { Table } from 'antd'
import { TweenOneGroup } from 'rc-tween-one'

const TableContext = React.createContext(false)

const enterAnim = [
  {
    opacity: 0,
    x: 30,
    backgroundColor: '#fffeee',
    duration: 0
  },
  {
    height: 0,
    duration: 200,
    type: 'from',
    delay: 250,
    ease: 'easeOutQuad'
  },
  {
    opacity: 1,
    x: 0,
    duration: 250,
    ease: 'easeOutQuad'
  },
  { delay: 1000, backgroundColor: '#fff' }
]

const leaveAnim = [
  { duration: 250, opacity: 0 },
  { height: 0, duration: 200, ease: 'easeOutQuad' }
]

class AnimatedTable extends PureComponent {
  animTag = $props => {
    return (
      <TableContext.Consumer>
        {() => {
          return (
            <TweenOneGroup
              component="tbody"
              enter={this.props.animation ? enterAnim : null}
              leave={this.props.animation ? leaveAnim : null}
              appear={false}
              exclusive
              {...$props}
            />
          )
        }}
      </TableContext.Consumer>
    )
  }

  render() {
    let additionalTableParams = {}
    if (this.props.animation) {
      additionalTableParams.components = { body: { wrapper: this.animTag } }
    }

    return (
      <TableContext.Provider value={{}}>
        <Table
          columns={this.props.columns}
          dataSource={this.props.dataSource}
          pagination={this.props.pagination}
          onChange={this.props.onChange}
          rowClassName={this.props.rowClassName}
          style={this.props.style}
          showSorterTooltip={this.props.showSorterTooltip}
          {...additionalTableParams}
        />
      </TableContext.Provider >
    )
  }
}

export default AnimatedTable
