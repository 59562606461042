import React, { PureComponent } from 'react'
import { Input } from 'antd'

class InputField extends PureComponent {
  render() {
    return (
      <Input
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={(e) => this.props.onChange ?
          this.props.onChange(this.props.name, e.target.value) :
          null
        }
        onBlur={(e) => this.props.onSave ?
          this.props.onSave(this.props.name, e.target.value) :
          null
        }
        status={this.props.status}
      />
    )
  }
}

export default InputField