import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'

import { getData } from '../redux/actions/DataActions'

const { Option } = Select

let searchTimer

class StaticSelect extends PureComponent {
  componentDidMount() {
    this.props.getData(
      this.props.filter,
      this.props.endpoint,
      '',
      {
        'id': this.props.value
      }
    )
  }

  render() {
    const { filter, data } = this.props

    let dataItem = {
      isLoading: true,
      items: []
    }

    if (data[filter]) {
      dataItem = data[filter]
    }

    let items = dataItem.items.map(item => (
      <Option key={item.id} value={String(item.id)}>
        {this.props.getOptionValue(item)}
      </Option>
    ))

    let additionalParams = {}

    if (this.props.search) {
      additionalParams = {
        showSearch: true,
        onSearch: (key) => {
          clearTimeout(searchTimer)
          searchTimer = setTimeout(() => {
            this.props.getData(
              this.props.filter,
              this.props.endpoint,
              key
            )
          }, 500)
        },
        filterOption: value => true,
      }
    }

    let value = this.props.value

    if (!Array.isArray(value)) {
      value = value ? String(value) : undefined;
    } else {
      value = value.map(item => String(item))
    }

    return (
      <Select
        style={{ width: '100%' }}
        placeholder={this.props.placeholder}
        onChange={(value, option) => {
          if (this.props.onChange) {
            this.props.onChange(
              this.props.name,
              value,
              { [this.props.keyName]: option.children }
            )
          }

          if (this.props.onSave) {
            this.props.onSave(
              this.props.name,
              value
            )
          }
        }}
        mode={this.props.mode}
        value={value}
        loading={dataItem.isLoading}
        status={this.props.status}
        {...additionalParams}
      >
        {items}
      </Select >
    )
  }
}

const mapStateToProps = store => {
  return {
    data: store.data,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getData: (filter, endpoint, key = '', params = {}) =>
      dispatch(getData(filter, endpoint, key, params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticSelect)