import produce from 'immer'
import {
  DATA_LOAD_REQUEST,
  DATA_LOAD_SUCCESS,
} from '../actions/DataActions'

const initialState = {}

export default function state(state = initialState, action) {
  switch (action.type) {
    case DATA_LOAD_REQUEST:
      return produce(state, draftState => {
        if (draftState[action.payload.filter]) {
          draftState[action.payload.filter].isLoading = true
        } else {
          draftState[action.payload.filter] = {
            isLoading: true,
            items: []
          }
        }
      })
    case DATA_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState[action.payload.data.filter] = {
          isLoading: false,
          items: action.payload.data.results
        }
      })
    default:
      return state
  }
}