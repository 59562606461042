const config = {
  url: '/article/:id',
  getTitle: id => id === 'create' ? 'Создание статьи' : 'Редактирование статьи',
  getApiUrl: id => id === 'create' ? '/articles' : `/articles/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'author',
            title: 'Автор',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'button_text',
            title: 'Текст кнопки',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'button_link',
            title: 'Ссылка кнопки',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
        ],
        right: [
          {
            key: 'abstract',
            title: 'Аннотация',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
        both: [
          {
            key: 'content',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    }
  ]
}

export default config