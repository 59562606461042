import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'antd'

import { getTable } from '../redux/actions/TableActions'

import getField from '../utils/getField'

class Filters extends PureComponent {
  render() {
    const config = this.props.config
    const filters = config.filters
    const filterItems = filters.map(item => (
      <Col span={8} key={item.key} className="Filters__col">
        {getField(
          {
            ...item,
            onChange: (key, value) => this.props.getTable(
              config.url,
              config.endpoint,
              1,
              {
                ...this.props.table.reqParams,
                params: {
                  ...this.props.table.reqParams.params,
                  [key]: value
                }
              }
            ),
            value: this.props.table.reqParams.params[item.key]
          }
        )}
      </Col>
    ))

    if (filterItems.length === 0) {
      return null
    }

    return (
      <Row gutter={16} className="Filters__row">
        {filterItems}

        <Col span={7} className="Filters__col">
          <Button
            type="dashed"
            onClick={e => {
              this.props.getTable(
                config.url,
                config.endpoint,
                1,
                {
                  ...this.props.table.reqParams,
                  params: {}
                }
              )
            }}
          >
            сбросить
          </Button>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = store => {
  return {
    table: store.table,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTable: (url, endpoint, page = 1, params = {}) =>
      dispatch(getTable(url, endpoint, page, params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters)