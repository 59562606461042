import { ROLES } from "./usersTable"

const config = {
  url: '/user/:id',
  getTitle: id => id === 'create' ? 'Создание пользователя' : 'Редактирование пользователя',
  getApiUrl: id => id === 'create' ? '/users' : `/users/${id}`,
  type: 'card',
  access: ['admin'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'name',
            title: 'Имя',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'surname',
            title: 'Фамилия',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'role',
            title: 'Роль',
            component: 'StaticSelect',
            options: ROLES,
            placeholder: 'Выберите роль',
            required: true,
          },
        ],
        right: [
          {
            key: 'email',
            title: 'Email',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'password',
            title: 'Пароль',
            component: 'Password',
            required: false,
          },
          {
            key: 'password_repeat',
            title: 'Повторите пароль',
            component: 'Password',
            required: false,
            checkValue: data => {
              return data.password === data.password_repeat || !data.password && !data.password_repeat
            }
          },
        ],
        both: [],
      },
    }
  ],
}

export default config