import React, { PureComponent } from 'react'
import { Upload, Button, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'

import { setField } from '../redux/actions/CardActions'

const TYPES_TO_MIMES = {
  xls: ['application/vnd.ms-excel'],
  xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  doc: ['application/msword'],
  docx: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  pdf: ['application/pdf'],
  ppt: ['application/vnd.ms-powerpoint'],
  pptx: ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  jpg: ['image/jpeg']
}

class FileField extends PureComponent {

  render() {
    const { card, keyList, keyName } = this.props

    const isSingleFile = this.props.maxCount === 1
    let files = []
    if (card.fields[keyList]) {
      files = isSingleFile ? [card.fields[keyList]] : card.fields[keyList]
    }
    let fileList = files.map(item => ({
      status: 'done',
      ...item,
      uid: item.uid ? item.uid : item.id,
    }))

    const url = process.env.REACT_APP_API_HOST
    const self = this
    const props = {
      name: 'file',
      action: `${url}/storage/store`,
      headers: {
        authorization: 'Bearer ' + this.props.user.attributes.token,
      },
      maxCount: this.props.maxCount,
      multiple: this.props.multiple,
      fileList,
      beforeUpload: file => {
        let isAllow = false
        if (self.props.allowTypes) {
          self.props.allowTypes.forEach(type => {
            if (TYPES_TO_MIMES[type].indexOf(file.type) !== -1) {
              isAllow = true
            }
          });
        } else {
          isAllow = true
        }
        if (!isAllow) {
          message.error(`${file.name} неверный формат файла`);
        }
        return isAllow || Upload.LIST_IGNORE;
      },
      onChange(info) {
        if (isSingleFile) {
          // single file
          if (info.file.status === 'done') {
            self.props.setField(keyList, info.file.response.data)
            self.props.setField(keyName, info.file.response.data.id)
          } else {
            self.props.setField(keyList, info.fileList[0])
            if (info.file.error) {
              if (info.file.error.status === 422) {
                message.error(`${info.file.name} неверный формат файла`)
              } else {
                message.error(
                  `${info.file.name} загрузка прервана. Код ошибки ${info.file.error.status}`
                )
              }
            }
          }

          if (info.fileList.length === 0) {
            self.props.setField(keyList, null)
            self.props.setField(keyName, null)
          }
        } else {
          // multiple files
          let localFileList = fileList.slice(0)
          const fileIndex = localFileList.map(item => item.uid).indexOf(info.file.uid)

          if (info.file.status === 'done') {
            localFileList[fileIndex] = info.file.response.data
            self.props.setField(keyList, localFileList)
            self.props.setField(keyName, localFileList.map(item => item.id))
          } else {
            self.props.setField(keyList, info.fileList.map(item => ({
              ...item,
              status: item.status ? item.status : ''
            })))
            if (info.file.error) {
              if (info.file.error.status === 422) {
                message.error(`${info.file.name} неверный формат файла`)
              } else {
                message.error(
                  `${info.file.name} загрузка прервана. Код ошибки ${info.file.error.status}`
                )
              }
            }
          }
        }
      },
    }

    return (
      <Upload {...props}>
        <Button
          icon={<UploadOutlined />}
          disabled={fileList.length >= this.props.maxCount && this.props.maxCount > 1}>
          Upload
        </Button>
      </Upload >
    );
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    card: store.card,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(setField(field, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileField)