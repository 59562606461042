import moment from "moment"

const config = {
  url: '/document/:id',
  getTitle: id => id === 'create' ? 'Создание документа' : 'Редактирование документа',
  getApiUrl: id => id === 'create' ? '/documents' : `/documents/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'document_type_id',
            title: 'Тип документа',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=document_types&sort[field]=title&sort[type]=asc&limit=20',
            filter: 'document_types',
            keyName: 'document_types_title',
            placeholder: 'Выберите тип документа',
            search: true,
            mode: 'single',
            getOptionValue: data => data.title,
            required: false,
          },
          {
            key: 'type_date_date',
            title: 'Дата',
            component: 'Date',
            placeholder: 'Введите',
            showTime: false,
            format: 'YYYY-MM-DD',
            style: { width: '100%' },
            defaultValue: moment().format('YYYY-MM-DD'),
            getValue: data => moment(data.type_date_timestamp, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            required: true,
            allowClear: false,
          },
          {
            key: 'place_name',
            title: 'Место',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'addressee',
            title: 'Адресат',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'is_show_on_main_page',
            title: 'Показывать на главной',
            component: 'Switch',
            defaultValue: 0,
          },
        ],
        right: [
          {
            key: 'biochronology',
            title: 'Биохроника Петра',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'place_id',
            title: 'Место хранения',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=places&sort[field]=title&sort[type]=asc&limit=20',
            filter: 'places',
            keyName: 'places_title',
            placeholder: 'Выберите место хранения',
            search: false,
            mode: 'single',
            getOptionValue: data => data.title,
            required: false,
          },
          {
            key: 'cipher',
            title: 'Шифр',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'publication',
            title: 'Публикация',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'publication_storage_id',
            title: 'Публикация (pdf)',
            component: 'File',
            multiple: false,
            maxCount: 1,
            keyName: 'publication_storage_id',
            keyList: 'publication_storage',
            allowTypes: ['pdf'],
          },
        ],
        both: [
          {
            key: 'comment',
            title: 'Комментарий',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
          /*
          {
            key: 'decoding',
            title: 'Расшифровка',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
          */
        ],
      }
    },
    {
      key: 'images',
      title: 'Изображения',
      children: {
        left: [
          {
            key: 'image_ids',
            title: 'Оригинальные изображения',
            component: 'Image',
            multiple: true,
            maxCount: 20,
            keyName: 'image_ids',
            keyList: 'images',
            allowTypes: ['jpg'],
            showInfo: true,
            isDigitalPetr: true,
            descriptionPlaceholder: 'Шифр',
          },
        ],
        right: [
          {
            key: 'publication_ids',
            title: 'Изображения публикации',
            component: 'Image',
            multiple: true,
            maxCount: 20,
            keyName: 'publication_ids',
            keyList: 'publications',
            allowTypes: ['jpg'],
            showInfo: true,
            isDigitalPetr: false,
            descriptionPlaceholder: 'Источник',
          },
        ],
        both: [
          {
            key: 'image_storage_id',
            title: 'Главное изображение',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
            showInfo: false,
            isDigitalPetr: false,
            descriptionPlaceholder: 'Шифр',
          },
        ],
      }
    }
  ]
}

export default config