import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { history } from './redux/configureStore'
import Login from './containers/LoginContainer'
import MainLayout from './layouts/MainLayout'

class App extends PureComponent {
  render() {
    if (history.location.pathname === '/login' && !this.props.user.authorized) {
      return <Login />
    }

    return <MainLayout />
  }
}

const mapStateToPtops = store => {
  return {
    user: store.user,
  }
}

export default connect(mapStateToPtops)(App);