export const INIT_CARD = 'CARD/INIT_CARD'

export function initCard(fields, url) {
  return dispatch => {
    dispatch({
      type: INIT_CARD,
      payload: {
        fields,
        url,
      }
    })
  }
}

export const SET_FIELD = 'CARD/SET_FIELD'

export function setField(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD,
      payload: {
        field,
        value,
      }
    })
  }
}

export const CREATE_REQUEST = 'CARD/CREATE_REQUEST'
export const CREATE_SUCCESS = 'CARD/CREATE_SUCCESS'

export function createCard(url, data) {
  return dispatch => {
    dispatch({
      types: [CREATE_REQUEST, CREATE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url,
          data,
        },
      }
    })
  }
}

export const CLEAR_REDIRECT = 'CARD/CLEAR_REDIRECT'

export function clearRedirect() {
  return dispatch => {
    dispatch({
      type: CLEAR_REDIRECT,
    })
  }
}

export const GET_REQUEST = 'CARD/GET_REQUEST'
export const GET_SUCCESS = 'CARD/GET_SUCCESS'

export function getCard(url, cardUrl) {
  return dispatch => {
    dispatch({
      types: [GET_REQUEST, GET_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url,
        },
        cardUrl
      }
    })
  }
}

export const UPDATE_REQUEST = 'CARD/UPDATE_REQUEST'
export const UPDATE_SUCCESS = 'CARD/UPDATE_SUCCESS'

export function updateCard(url, data) {
  return dispatch => {
    dispatch({
      types: [UPDATE_REQUEST, UPDATE_SUCCESS],
      payload: {
        request: {
          method: 'PUT',
          url,
          data,
        },
      }
    })
  }
}

export const SET_VALIDATION = 'CARD/SET_VALIDATION'

export function setValidation(errorFields) {
  return dispatch => {
    dispatch({
      type: SET_VALIDATION,
      payload: {
        errorFields,
      }
    })
  }
}