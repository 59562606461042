import qs from 'qs'
import { history } from '../configureStore'

export const TABLE_LOAD_REQUEST = 'TABLE/TABLE_LOAD_REQUEST'
export const TABLE_LOAD_SUCCESS = 'TABLE/TABLE_LOAD_SUCCESS'

export function getTable(url, endpoint, page = 1, params = {}) {
  return dispatch => {
    history.push({
      pathname: url,
      search: qs.stringify(params),
    })

    dispatch({
      types: [TABLE_LOAD_REQUEST, TABLE_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: endpoint,
          params: {
            page,
            ...params
          },
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        }
      }
    })
  }
}

export const SET_REQ_PARAM = 'TABLE/SET_REQ_PARAM'

export function setReqParam(param, value) {
  return dispatch => {
    dispatch({
      type: SET_REQ_PARAM,
      payload: {
        param,
        value
      }
    })
  }
}

export const RESET_REQ_PARAM = 'TABLE/RESET_REQ_PARAM'

export function resetReqParam() {
  return dispatch => {
    dispatch({
      type: RESET_REQ_PARAM,
    })
  }
}

export const SET_TABLE_PARAM = 'TABLE/SET_TABLE_PARAM'

export function setTableParam(id, param, value, other = {}) {
  return dispatch => {
    dispatch({
      type: SET_TABLE_PARAM,
      payload: {
        id,
        param,
        value,
        other
      }
    })
  }
}

export const UPDATE_CELL_REQUEST = 'TABLE/UPDATE_CELL_REQUEST'
export const UPDATE_CELL_SUCCESS = 'TABLE/UPDATE_CELL_SUCCESS'

export function updateCell(url, id, data) {
  return dispatch => {
    dispatch({
      types: [UPDATE_CELL_REQUEST, UPDATE_CELL_SUCCESS],
      payload: {
        request: {
          method: 'PUT',
          url,
          data,
        },
        id,
      }
    })
  }
}

export const DELETE_RECORD_REQUEST = 'TABLE/DELETE_RECORD_REQUEST'
export const DELETE_RECORD_SUCCESS = 'TABLE/DELETE_RECORD_SUCCESS'

export function deleteRecord(url, id) {
  return dispatch => {
    dispatch({
      types: [DELETE_RECORD_REQUEST, DELETE_RECORD_SUCCESS],
      payload: {
        request: {
          method: 'DELETE',
          url,
        },
        id,
      }
    })
  }
}