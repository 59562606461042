import moment from "moment"

export const TYPES = {
  0: 'Не определено',
  1: 'Тип один',
  2: 'Тип два',
}

const config = {
  url: '/examples',
  title: 'Примеры',
  endpoint: '/admin-search?filter=examples',
  type: 'table',
  getUrl: id => `/example/${id}`,
  getApiUrl: id => `/examples/${id}`,
  search: true,
  key: 'examples',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'type_input',
      width: 150,
      title: 'Тип Input',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.type_input,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },

    },
    {
      key: 'type_textarea',
      title: 'Тип TextArea',
      isViewByDefault: true,
      getValue: data => data.type_textarea,
      edit: {
        component: 'TextArea',
        placeholder: 'Введите текст',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'type_switch',
      width: 150,
      title: 'Тип Switch',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.type_switch ? 'Да' : 'Нет',
      edit: {
        component: 'Switch',
      },
    },
    {
      key: 'type_date_unix',
      title: 'Тип Дата unix',
      isViewByDefault: false,
      getValue: data => moment.unix(data.type_date_unix).format('DD.MM.YYYY HH:mm'),
      width: 180,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        unix: true,
        showTime: true,
        required: true,
      },
    },
    {
      key: 'type_date_timestamp',
      title: 'Тип Дата timestamp',
      isViewByDefault: false,
      getValue: data => moment(data.type_date_timestamp, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm'),
      width: 180,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: true,
        format: 'YYYY-MM-DD HH:mm:ss',
        required: true,
      },
    },
    {
      key: 'type_dynamic_select_page_id',
      width: 150,
      title: 'Тип DynamicSelect',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.pages_title,
      edit: {
        component: 'DynamicSelect',
        endpoint: '/admin-search?filter=pages&sort[field]=title&sort[type]=asc&limit=20',
        filter: 'pages',
        key: 'type_dynamic_select_page_id',
        keyName: 'pages_title',
        placeholder: 'Выберите страницу',
        search: true,
        mode: 'single',
        getOptionValue: data => data.title,
        required: true,
      }
    },
    {
      key: 'type_static_select',
      width: 150,
      title: 'Тип StaticSelect',
      sorter: true,
      isViewByDefault: false,
      getValue: data => TYPES[data.type_static_select],
      edit: {
        component: 'StaticSelect',
        options: TYPES,
        placeholder: 'Выберите тип',
      },
    },
  ],
  filters: [
    {
      key: 'id',
      component: 'Input',
      placeholder: 'Введите ID',
      maxLength: 255,
    },
    {
      key: 'type_switch',
      component: 'Switch',
      placeholder: 'Тип Switch',
    },
    {
      key: 'type_static_select',
      component: 'StaticSelect',
      options: TYPES,
      placeholder: 'Выберите тип',
      mode: 'multiple'
    },
    {
      key: 'type_dynamic_select_page_id',
      component: 'DynamicSelect',
      endpoint: '/admin-search?filter=pages&sort[field]=title&sort[type]=asc&limit=20',
      filter: 'pages',
      placeholder: 'Выберите страницу',
      search: true,
      getOptionValue: data => data.title,
    }
  ],
}

export default config
