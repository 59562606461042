export const USER_LOAD_REQUEST = 'PERSONAL/USER_LOAD_REQUEST'
export const USER_LOAD_SUCCESS = 'PERSONAL/USER_LOAD_SUCCESS'

export function getUserData() {
  return dispatch => {
    dispatch({
      types: [USER_LOAD_REQUEST, USER_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/personal/user',
        }
      }
    })
  }
}

export const UPDATE_USER_REQUEST = 'PERSONAL/UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'PERSONAL/UPDATE_USER_SUCCESS'

export function updateUserData(data) {
  return dispatch => {
    dispatch({
      types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS],
      payload: {
        request: {
          url: '/personal/user',
          method: 'PUT',
          data,
        },
      }
    })
  }
}