import produce from 'immer'
import {
  SERVER_ERROR,
} from '../actions/ServerActions'

const initialState = {
  error: {
    status: null,
    text: '',
  },
}

export default function state(state = initialState, action) {
  switch (action.type) {
    case SERVER_ERROR:
      return produce(state, draftState => {
        if (!action.payload) return
        draftState.error.status = action.payload.status
        draftState.error.text = action.payload.text
      })
    default:
      return state
  }
}