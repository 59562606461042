import qs from 'qs'

export const DATA_LOAD_REQUEST = 'DATA/DATA_LOAD_REQUEST'
export const DATA_LOAD_SUCCESS = 'DATA/DATA_LOAD_SUCCESS'

export function getData(filter, endpoint, key = '', params = {}) {
  return dispatch => {
    dispatch({
      types: [DATA_LOAD_REQUEST, DATA_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: endpoint,
          params: {
            key,
            params
          },
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        },
        filter
      }
    })
  }
}