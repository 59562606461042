import moment from "moment"

const config = {
  url: '/',
  title: 'Документы',
  endpoint: '/admin-search?filter=documents',
  type: 'table',
  getUrl: id => `/document/${id}`,
  getApiUrl: id => `/documents/${id}`,
  search: true,
  key: 'documents',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'document_type_id',
      width: 150,
      title: 'Тип документа',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.document_types_title,
      edit: {
        component: 'DynamicSelect',
        endpoint: '/admin-search?filter=document_types&sort[field]=title&sort[type]=asc&limit=20',
        filter: 'document_types',
        key: 'document_type_id',
        keyName: 'document_types_title',
        placeholder: 'Выберите тип документа',
        search: false,
        mode: 'single',
        getOptionValue: data => data.title,
        required: false,
      }
    },
    {
      key: 'place_name',
      title: 'Место',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.place_name,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: false,
      },
    },
    {
      key: 'place_id',
      width: 150,
      title: 'Место хранения',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.places_title,
      edit: {
        component: 'DynamicSelect',
        endpoint: '/admin-search?filter=places&sort[field]=title&sort[type]=asc&limit=20',
        filter: 'places',
        key: 'place_id',
        keyName: 'places_title',
        placeholder: 'Выберите место хранения',
        search: false,
        mode: 'single',
        getOptionValue: data => data.title,
        required: false,
      }
    },
    {
      key: 'cipher',
      title: 'Шифр',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.cipher,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: false,
      },
    },
    {
      key: 'type_date_date',
      title: 'Дата',
      isViewByDefault: false,
      getValue: data => moment(data.type_date_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      width: 150,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: false,
        format: 'YYYY-MM-DD',
        required: false,
      },
    },
  ],
  filters: [
    {
      key: 'document_type_id',
      component: 'DynamicSelect',
      endpoint: '/admin-search?filter=document_types&sort[field]=title&sort[type]=asc&limit=20',
      filter: 'document_types',
      placeholder: 'Выберите тип документа',
      search: false,
      getOptionValue: data => data.title,
    },
    {
      key: 'place_id',
      component: 'DynamicSelect',
      endpoint: '/admin-search?filter=places&sort[field]=title&sort[type]=asc&limit=20',
      filter: 'places',
      placeholder: 'Выберите место хранения',
      search: false,
      getOptionValue: data => data.title,
    },
    {
      key: 'is_show_on_main_page',
      component: 'StaticSelect',
      options: {
        0: 'Нет',
        1: 'Да',
      },
      placeholder: 'Показывать на главной',
      mode: 'single'
    },
  ],
}

export default config