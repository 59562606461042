export const ROLES = {
  user: 'Пользователь',
  editor: 'Редактор',
  admin: 'Администратор',
}

const config = {
  url: '/users',
  title: 'Пользователи',
  endpoint: '/admin-search?filter=users',
  type: 'table',
  getUrl: id => `/user/${id}`,
  getApiUrl: id => `/users/${id}`,
  search: true,
  key: 'users',
  access: ['admin'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'name',
      width: 150,
      title: 'Имя',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.name,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'surname',
      width: 150,
      title: 'Фамилия',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.surname,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: false,
      },
    },
    {
      key: 'email',
      width: 150,
      title: 'Email',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.email,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: false,
      },
    },
    {
      key: 'role',
      width: 150,
      title: 'Роль',
      sorter: true,
      isViewByDefault: false,
      getValue: data => ROLES[data.role],
      edit: {
        component: 'StaticSelect',
        options: ROLES,
        placeholder: 'Выберите роль',
        required: true
      },
    },
  ],
  filters: [],
}

export default config