import produce from 'immer'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_NOT_FOUND,
  USER_LOGIN_SERVER_ERROR,
  USER_LOGIN_TOO_MANY_REQUESTS,
  USER_LOGIN_SUCCESS,
  USER_INIT_LOGIN,
  USER_LOGOUT,
  USER_UPDATE_SETTINGS,
  USER_UPDATE_UNLOADING,
} from '../actions/UserActions'

const initialState = {
  __persist: true,
  isLoading: false,
  authorized: false,
  type: '',
  id: '',
  attributes: {
    login: '',
    name: '',
    token: '',
  },
  error: {
    code: null,
    text: '',
  },
  settings: {
    tables: {}
  },
  unloading: {
    records: 1,
    fields: 1,
  }
}

export default function state(state = initialState, action) {
  switch (action.type) {
    case USER_UPDATE_UNLOADING:
      return produce(state, draftState => {
        draftState.unloading[action.payload.field] = action.payload.value
      })
    case USER_UPDATE_SETTINGS:
      return produce(state, draftState => {
        draftState.settings[action.payload.section][action.payload.field] = action.payload.value
      })
    case USER_INIT_LOGIN:
      return produce(state, draftState => {
        draftState.isLoading = false
      })
    case USER_LOGIN_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case USER_LOGIN_NOT_FOUND:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.error = {
          code: 404,
          text: 'Введен неверный логин или пароль',
        }
      })
    case USER_LOGIN_TOO_MANY_REQUESTS:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.authorized = false
        draftState.error = {
          code: 429,
          text:
            'Слишком много попыток входа. Попробуйте снова немного позднее',
        }
      })
    case USER_LOGIN_SERVER_ERROR:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.authorized = false
        draftState.error = {
          code: 500,
          text:
            'Возникла проблема со входом. Попробуйте еще раз и обратитесь в службу поддержки, если проблема повторится',
        }
      })
    case USER_LOGIN_SUCCESS:
      const { data } = action.payload
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.authorized = true
        draftState.error = {
          code: null,
          text: '',
        }
        draftState.type = data.type
        draftState.id = data.id
        draftState.attributes = data
      })
    case USER_LOGOUT:
      return Object.assign({}, initialState, { settings: state.settings })
    default:
      return state
  }
}
